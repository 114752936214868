import React from 'react';
import Header from '../../../components/Header/navmap/landingVc';
import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Hero from './Hero';
import ContactCustomers from './ContactCustomers';
import PeaceOfMind from './PeaceOfMind';
import Perks from './Perks';
import Clients from './Clients';
import Testimonials from './Testimonials';

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.landingVc._}
    header={Header}
    isLanding={'vc'}
    url='_url:landing-vc'
    homeLink={i18n('_url:landing-vc')}>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Clients/>
    <ContactCustomers/>
    <PeaceOfMind/>
    <Perks/>
    <Testimonials/>
  </Layout>
);